import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isDoneLoading: Observable<boolean>;

  constructor(
    private authService: AuthService,
  ) {
    this.isDoneLoading = this.authService.isDoneLoading$;
    this.authService.runInitialLoginSequence();

  }

}
