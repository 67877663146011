<!-- <div>
    <div>
        <p>
            Reason: {{data.reason}}
        </p>
        <p>
            Status: {{data.status}}
        </p>
        <p>
            Message: {{data.message}}
        </p>
        <p>
            Ok: {{data.ok}}
        </p>
        <p>
            Error : {{data.detail}}
        </p>
  
    </div>
  </div> -->

 <!-- <div class="text-center p-4">
      <h3>Attenzione</h3>
      <p>{{data.message}}</p>
      <a (click)="closeModal()" class="ok-btn">
       OK
      </a>
  </div> -->

  <div id="modal-content-wrapper">
    <header id="modal-header">
        <h1 id="modal-title">Attenzione</h1>
    </header>
    <section id="modal-body">
      <h3 class="text-center">{{data.message}}</h3>
    </section>
    <footer id="modal-footer">
      <!-- <a class="cancel-button" (click)="closeModal()">
        {{ modalData.cancelButtonText }}
      </a> -->
      <a (click)="closeModal()">
        OK
      </a>
      
      
    </footer>
  </div>
  