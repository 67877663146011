import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: environment.issuer, // 'https://localhost:5003',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/index.html',

  // URL of the SPA to redirect the user after silent refresh
  // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.clientId, // 'ro.client',

  // questo non ha alcuna importanza ma va lasciato (il valore può essere qualunque)
  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email', // offline_access per il refresh silent

  customQueryParams: {
    acr_values: 'idp:Facebook',
  },


  showDebugInformation: true,
  
  oidc: false
};
