import { Injectable } from '@angular/core';
import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('interceptor ' + sessionStorage.getItem('access_token'));
        if (!environment.production) {
            if (!request.headers.has('Content-Type')) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }

            request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('interceptor error');
                let data = {};
                if (error.status && error.status === 422) {
                    data = {
                        message : 'Dati già confermati, Verrai contattato dal nostro team nei prossimi giorni',
                    };
                }
                else {
                    data = {
                        reason: error && error.error && error.error.reason ? error.error.reason : '',
                        status: error.status,
                        message : error.message,
                        ok: error.ok,
                        detail: error && error.error && error.error.detail ? error.error.detail : ''
                    };
                }
                this.errorDialogService.openDialog(data);
                return throwError(error);
            })
        );
    }
}
