import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MyMaterialModule } from './material.module';
import { NotFoundComponent } from './notfound.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ModalComponent } from '../app/components/modal/modal.component';
import { FooterComponent } from '../app/components/footer/footer.component';
import { ApiModule, BASE_PATH } from 'lms-api';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalActionsService } from '../app/components/modal/modal-actions.service';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from './core/core.module';

const cookieConfig: NgcCookieConsentConfig = {

  cookie: {
    domain: ''
  },
  position: 'bottom',
  theme: 'block',
  palette: {
    popup: {
      background: '#626262',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#626262',
      text: '#ffffff',
      border: '1px solid #ffffff'
  }
  },
  type: 'info',
  content: {
    message: 'Promarsa Italia, utilizza cookies di proprietà e di terze parti con lo scopo di facilitare la tua esperienza sul nostro sito web e analizzare la tua navigazione a fini statistici e analitici. Se vuoi accettarli, clicca sul bottone \'Accetta cookies\'.',
    dismiss: 'Accetta Cookies',
    /* deny: 'Rifiuta Cookies', */
    link: 'Scopri di più',
    href: 'https://www.tuttipremiati.it/cookie-policy.action',
    policy: 'Cookie Policy'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    FooterComponent,
    ModalComponent,
    ErrorDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    MyMaterialModule,
    ApiModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatDialogModule
  ],
  providers: [
    ApiModule,
    ModalActionsService,
    ErrorDialogService,
    { provide: BASE_PATH, useValue: environment.lmsApiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
