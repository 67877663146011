import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from './errordialog.component';

@Injectable()
export class ErrorDialogService {
    public isDialogOpen = false;
    constructor(public dialog: MatDialog, private router: Router) { }
    openDialog(data): any {
        if (this.isDialogOpen) {
            return false;
        }
        this.isDialogOpen = true;
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: 'auto',
            panelClass: 'myapp-no-padding-dialog',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            this.isDialogOpen = false;
            this.router.navigateByUrl('/register');
        });
    }
}
