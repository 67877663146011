<div class="main-div">  
    <div class="header">
       <div class="header-content">
         <a [routerLink]="['/register']" class="link-img">
            <img src="../../assets/images/Logo_TuttiPremiati.png" alt="" class="logo"/>
         </a>
       </div>
       
    </div> 
    <router-outlet></router-outlet>
    
</div>

<mat-progress-spinner class="authenticating-loader" *ngIf="!(isDoneLoading | async)" color="primary" 
  diameter="100" mode="indeterminate" strokeWidth="true" value="50">
</mat-progress-spinner>

<app-footer></app-footer>