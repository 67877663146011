<footer>
    <div class="card-premiati">
        <div class="row">
            <div class="col-12">
                <div class="footer-widgets">		
                    <div class="wgt-links">
                        <a class="link link-cookies-policy" [routerLink]="['/cookies']" >Cookie Policy </a>
                        <a class="link link-terms-use" href="../../../assets/pdf/Termini_Condizioni.pdf" target="_blank">Condizioni d'uso </a>
                    </div>		
                </div>
                <div class="footer-inner">
                    <p class="footer-text">
                        Copyright © 2010-<span id="currentdate">2021</span> Vip District S.L. • Tutti i diritti riservati
                        <br/>Servizio di supporto agli agenti disponibile scrivendo <a href="mailto:'supporto@tuttipremiati.it'" class="link">supporto@tuttipremiati.it</a>
                    </p>
                
                    <p class="footer-text2">
                        TuttiPreMIAti è un servizio offerto da <img src="https://colectivosvip.com/public/colectivos/1226/logos-footer-2.svg" width="230">
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
